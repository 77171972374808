<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">系统设置</el-breadcrumb-item>
        <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form label-width="70px" class="search-form">
      <el-row>
        <!-- <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" prefix-icon="Search"></el-input>
          </el-col>
        </el-form-item> -->
        <el-form-item label="类别" prop="category">
          <el-col>
            <el-select v-model="category" placeholder="请选择类别" required @change="getMenus();" style="min-width: 120px;" >
              <el-option v-for="value in categorys" :label="value" :value="value"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="getMenus()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <div class="toolbar">
      <el-button type="primary" icon="plus" @click="rootDialog()">
        新增
      </el-button>
      <el-button type="primary" plain icon="fold">
        展开菜单
      </el-button>
      <el-button type="primary" plain icon="Expand">
        折叠菜单
      </el-button>
    </div>

    <el-table class="table" :data="menuData" row-key="id" border
      :tree-props="{ children: 'children', hasChildren: 'hasChildren1' }" :default-expand-all="false"
      highlight-current-row @current-change="currHandler" size="small">
      <el-table-column type="index" label="序号" align="center" width="60"> </el-table-column>
      <!-- <el-table-column prop="attribute" label="大类" width="80"> </el-table-column> -->
      <el-table-column prop="name" label="菜单名称" width="180"></el-table-column>
      <el-table-column prop="value" label="路由地址" width="300"></el-table-column>
      <el-table-column label="图标" width="130">
        <template #default="scope">
          <div style="line-height:15px; height:15px; display:flex;">
            <component :is="scope.row.icon" style="width: 15px; height:15px;" />
            <div> {{ scope.row.icon }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="attributeValue" label="排序号" width="80"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="160">
        <template #default="scope">
          <el-button @click="addDialog(scope.row)" type="primary" link size="small">
            新增
          </el-button>
          <el-button type="primary" link size="small" @click="editDialog(scope.row.id)">
            编辑
          </el-button>
          <el-button type="primary" link size="small" icon="delete" @click="deleHandler(scope.row.id)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>

  <el-dialog title="菜单维护" width="600px" destroy-on-close v-model="formDialogVisible" @close="close">
    <el-form :model="forms" :rules="rules" ref="forms" :label-width="formLabelWidth">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="菜单类别" prop="category">
            <el-select v-model="forms.category" placeholder="请选择类别" required>
              <el-option v-for="value in categorys" :label="value" :value="value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="上级菜单">
            <!-- <el-select v-model="forms.parentID" placeholder="请选择上级菜单" disabled>
              <el-option label="根菜单" value=""></el-option>
            </el-select> -->
            <el-tree-select v-model="forms.parentID" :data="menuSelect" check-strictly :render-after-expand="false" />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="菜单名称" prop="name">
            <el-input v-model="forms.name" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="菜单编号">
            <el-input v-model="forms.code" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="菜单类型">
            <el-radio v-model="forms.menuType" label="page">菜单</el-radio>
            <el-radio v-model="forms.menuType" label="button">按钮</el-radio>
          </el-form-item>
        </el-col> -->
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="排序号">
            <el-input-number v-model="forms.sortCode" :min="1" :max="9999999" label="排序号"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="样式图标">
            <el-input v-model="forms.icon" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="菜单状态">
            <el-radio v-model="forms.status" label="enabled">启用</el-radio>
            <el-radio v-model="forms.status" label="disabled">禁用</el-radio>
          </el-form-item>
        </el-col> -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="路由地址">
            <el-input v-model="forms.target" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="formDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { fetchMenus,fetchMenuDetail, submitMenu, delMenu } from "@/api/basic";
export default {
  name: "MenuPage",
  components: {},
  data() {
    return {
      category: '后台',
      categorys: ['后台', '侧边栏'],
      forms: {
        keyword: "",
      },
      currRow: null,
      menuData: [],
      formDialogVisible: false,
      forms: {},
      formLabelWidth: "90px",
      rules: {
        category: [{ required: true, message: "", trigger: "blur" }],
        name: [
          { required: true, message: "请输入菜单名称", trigger: "blur" },
        ],
        // url: [
        //   { required: true, message: "请输入菜单路由地址", trigger: "blur" },
        //   { min: 0, max: 50, message: "长度在0-50之间", trigger: "blur" },
        // ],
      },

    };
  },
  mounted() {
    this.getMenus();
  },
  methods: {
    editDialog(id) {
      this.forms = {};
      fetchMenuDetail(id).then((res) => {
        this.forms = res.data;
        this.formDialogVisible = true;
      });
    },
    addDialog(row) {
      //const res=await  this.$http.post('url',this.forms)
      this.formDialogVisible = true;
      this.forms = { parentID: row.id, category: row.attribute };
    },
    rootDialog() {
      this.forms = {};
      this.formDialogVisible = true;
    },
    close() {
      this.$refs.forms.resetFields();
    },
    currHandler(row) {
      this.currRow = row;
    },
    deleHandler(id) {
      let that = this;
      this.$confirm("此操作将删除本页选择数据, 是否继续?", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning", })
        .then(() => {
          delMenu(id).then((res) => {
            if (res.code === 200) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
              that.getMenus();
            }
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "删除失败", });
        });
    },
    getMenus() {
      var that = this;
      fetchMenus(this.category).then((res) => {
        that.menuData = JSON.parse(JSON.stringify(res)); //clone
        //console.log(that.menuData);
        that.menuSelect = that.loopTreeData(res);
      });
    },
    loopTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        data[i].label = data[i].name;
        data[i].value = data[i].id;
        if (data[i].children.length > 0) {
          data[i].children = this.loopTreeData(data[i].children);
        }
      }
      return data;
    },

    submit() {
      let that = this;

      this.$refs.forms.validate((valid) => {
        if (!valid) return;

        submitMenu(this.forms).then((res) => {
          if (res.code === 200) {
            that.$message({
              type: "success",
              message: res.content,
            });
            that.formDialogVisible = false;
            that.getMenus();
          }
        });
      });
    },
  },
};
</script>